<template>
	<div class="post">
		<Topq></Topq>
		<div class="post-content">
			<div class="main">
			<div class="main-title">
				<div class="wanted-top">
					<div class="top-wanted">你需要招聘什么岗位的员工？</div>
				</div>
				<div class="wanted-banner">
					编辑你的需求，匹配更多精准优秀人材
				</div>	
			</div>
			
			<div class="wanted-state">
				<span>招聘类型：</span>
				<div class="wanted-state-main">
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						<span>{{ item.label }}</span>
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>工作地区：</span>
				<div class="wanted-state-main">
					<area-select type='code' :level='2' v-model="selected" :data="pcaa"></area-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>招聘行业：</span>
				<div class="wanted-state-main">
					<el-select v-model="hangvalue" placeholder="请选择" @change="hang">
						<el-option v-for="(item,index) in industryInfo" :key="index" :label="item.industryName"
							:value="item.industryId">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>招聘职位：</span>
				<div class="wanted-state-main">
					<el-select v-model="zhivalue" placeholder="请选择">
						<el-option v-for="(i,j) in categories" :key="j" :label="i.categoryName" :value="i.categoryId">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>职位名称：</span>
				<div class="wanted-state-main">
					<div class="channel">
						<el-input v-model="zhiname" placeholder="请输入职位名称"></el-input>
					</div>
				</div>
			</div>
			<div class="wanted-state">
				<span>薪资要求：</span>
				<div class="wanted-state-main">
					<el-select v-model="xinvalue" placeholder="请选择">
						<el-option v-for="item in xinoptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>学历要求：</span>
				<div class="wanted-state-main">
					<el-select v-model="xuevalue" placeholder="请选择">
						<el-option v-for="item in xueoptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state">
				<span>经验要求：</span>
				<div class="wanted-state-main">
					<el-select v-model="jingvalue" placeholder="请选择">
						<el-option v-for="item in jingoptions" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="wanted-state-miao">
				<span>职位描述：</span>
				<div class="fanwei-mian">
					<el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="postextarea">
					</el-input>
				</div>
			</div>
			<div class="post-button" @click="submit">
				保存
			</div>
		</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import $http from '../common/api/axios.js'
	import {
		pubPost,industryInfo,getPubPost
	} from '../common/api/api.js'
	import {
		pcaa
	} from 'area-data';
	import Topq from '../components/common/Topq.vue'
	import Footer from './common/Footer.vue'
	export default {
		data() {
			return {
				postextarea: '',
				selected: [], // 接收数据的对象
				pcaa: pcaa,
				options: [{
					value: '0',
					label: '全职'
				}, {
					value: '1',
					label: '实习全职'
				},  {
					value: '2',
					label: '实习兼职'
				},{
					value: '3',
					label: '兼职'
				}],
				value: '',
				zhiname:'',
				industryInfo: [],
				categories: [],
				zhivalue:'',
				hangvalue:'',
				jingvalue:'',
				jingoptions:[{
					value: '0',
					label: '无限制'
				}, {
					value: '1',
					label: '在校生'
				},  {
					value: '2',
					label: '应届生'
				},{
					value: '3',
					label: '1年以内'
				},{
					value: '4',
					label: '1-3年'
				},{
					value: '5',
					label: '3-5年'
				},{
					value: '6',
					label: '5-10年'
				},{
					value: '7',
					label: '10年以上'
				}],
				xuevalue:'',
				xueoptions:[{
					value: '0',
					label: '无限制'
				}, {
					value: '1',
					label: '小学以上（含）'
				},  {
					value: '2',
					label: '初中以上'
				},{
					value: '3',
					label: '高中以上'
				},{
					value: '4',
					label: '专科以上'
				}
				,{
					value: '5',
					label: '本科以上'
				},{
					value: '6',
					label: '硕士以上'
				},{
					value: '7',
					label: '博士以上'
				},{
					value: '8',
					label: '博士后以上'
				}],
				xinvalue:'',
				xinoptions: [{
					value: '0',
					label: '0-5000'
				}, {
					value: '1',
					label: '5000-8000'
				}, {
					value: '2',
					label: '8000-11000'
				}, {
					value: '3',
					label: '11000-15000'
				}, {
					value: '4',
					label: '15000-20000'
				}, {
					value: '5',
					label: '20000-30000'
				}, {
					value: '6',
					label: '30000-50000'
				}, 
				{
					value: '7',
					label: '50000以上'
				}],
			}
		},
		created() {
			// this.selected[0] = pcaa[86][110000]; 
			// this.selected[1] = pcaa[110000][110100]; 
			// this.selected[2] = pcaa[110100][110101]; 
			let that=this
			let id = this.$route.query.id			
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url: industryInfo,
				}).then((res) => {
					
					that.industryInfo = res.data.industryInfos
					if(id!==undefined){
						$http.request({
							url:getPubPost,
							data:{
								"postId": id
							}
						}).then((res) => {
							let provinceId=res.data.provinceId
							let cityId=res.data.cityId
							let districtId=res.data.districtId; 
							that.selected=[provinceId, cityId,districtId]						
							that.zhiname=res.data.postTitle						
							that.hangvalue=res.data.category.industryType						
							that.zhivalue=res.data.category.categoryId					
							that.postextarea=res.data.workContent				
							that.categories=that.industryInfo[res.data.category.industryType-1].categories
							
							that.xinvalue=res.data.salaryType.toString()
							that.value=res.data.postType.toString();
							that.xuevalue=res.data.eduRequire.toString()
							that.jingvalue=res.data.expRequire.toString()
						}).catch(() => {
									
						});
					}
				}).catch(() => {
			
				});
				
			})
			
			
		},
		components: {
			Topq,
			Footer

		},
		methods: {
			hang(e) {
				console.log(e)
				this.zhivalue = ''
				let num = e - 1
				this.categories = this.industryInfo[num].categories
			},
			submit() {
				let id = this.$route.query.id
				let that=this			
						$http.request({
							url: pubPost,
							data: {
								"postId": id,
								"cityId": that.selected[1],
								"districtId": that.selected[2],
								"provinceId": that.selected[0],
								"category": that.zhivalue,
								"eduRequire": that.xuevalue,
								"expRequire": that.jingvalue,
								"industryType": that.hangvalue,
								"salaryType":that.xinvalue,
								"postTitle": that.zhiname,
								"postType":that.value.toString(),
								"workContent": that.postextarea
							}
						}).then((res) => {
							if (res.code == 200) {
								this.$message({
									message: '添加成功!',
									type: 'success',
									duration:'2000'
								});
								that.$router.push('./pj-recruit')
							}
						}).catch(() => {
					
						})
					
				
			}
		}
	}
</script>

<style scoped>
	.main-title{
		margin-bottom: .2rem;
	}
	.wanted-top {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		width: 11.6rem;
		
	}
	
	.top-wanted {
		font-size: .24rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: 30px;
	}
	.wanted-banner {
		
		margin-bottom: .28rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 30px;
	}
	/deep/.area-select .area-select-icon {
		border-top-color: #e1e1e1 !important
	}

	/deep/.el-textarea__inner {
		width: 8.8rem !important;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
	}

	.wanted-state-miao {
		display: flex;
	}

	.wanted-state-miao span {
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
	}
	.post-button {
		margin-top: .6rem;
		margin-left: 1.9rem;
		margin-bottom: .4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 5.67rem;
		height: .64rem;
		background: #E41D1D;
		border-radius: 6px;
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	/deep/.area-select-wrap .area-select {
		margin-left: 0;
	}

	/deep/.area-selected-trigger {
		display: flex !important;
		align-items: center !important;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	/deep/.area-select.medium {
		width: 2.88rem !important;
		height: .54rem !important;
		/* line-height:.4rem!important ; */
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.post {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.post-content {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}
	.main{
		box-sizing: border-box;
		padding-top: .7rem;
		padding-left: .8rem;
		display: flex;
		flex-direction: column;
		width: 12rem;
		border: 1px solid rgba(172, 172, 172, 0.2);
		box-sizing: border-box;
	}
	.wanted-state {
		display: flex;
		margin-bottom: .2rem;
	}

	.wanted-state span {
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: .54rem;
	}
	/deep/.el-input__inner {
		width: 8.8rem!important;
		height: .54rem !important;
		font-size: .16rem !important;
		font-family: PingFang SC !important;
		font-weight: 400 !important;
		color: #333333 !important;
	}

	input.el-input__inner::-webkit-input-placeholder {
		color: #CCCCCC !important;
		font-weight: 400 !important;
	}

	input.el-input__inner::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #CCCCCC !important;
		font-weight: 400 !important;
	}

	input.el-input__inner:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #CCCCCC !important;
		font-weight: 400 !important;
	}

	input.el-input__inner:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #CCCCCC !important;
		font-weight: 400 !important;
	}

	.el-input__suffix {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/deep/.el-input__icon {
		line-height: .54rem !important;
	}

	/deep/.el-input {
		font-size: .16rem !important;
		width: 8.8rem!important;
	}

	/deep/.el-select .el-input .el-select__caret {
		color: #333333;
	}

	.el-select-dropdown__item {
		font-size: .16rem !important;
		font-family: PingFang SC !important;
		font-weight: 400 !important;
		color: #333333 !important;
		line-height: .30rem !important;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border: 1px solid #762FEF !important;
	}
	/deep/.el-input__inner {
		text-align: center!important;
	}
</style>
